import "core-js/stable";
import "regenerator-runtime/runtime";
require("@rails/ujs").start()

import 'jquery/src/jquery';
import 'bootstrap/dist/js/bootstrap'

import './setup'
import './quiz_type_gate'
import './age_gate'
import './email_gate'
import  './close'
import './answer'
import './swipe'

