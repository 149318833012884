import { fetchUUID } from './user_uuid'
import { fetchLocation } from './location_utils'
import send from './send'

$('#email-gate-form').on('submit', function (e) {
  e.preventDefault()
  const form = $(this)
  const url = form.attr('action')
  const email = form.find('#email-field').val()

  if (!email) {
    form.find('.error-message').text('Please, fill in an email')
    return
  }

  if (!validateEmail(email)) {
    form.find('.error-message').text('Please, fill in an valid email')
    return
  }

  const data = {
    email_gate: {
      location_id: fetchLocation(),
      uuid: fetchUUID(),
      email: email,
    }
  }

  send(url, data)
    .finally(() => {
      console.log('sent')
      location.href = form.data("next-question-url") + '?' + 'uuid=' + fetchUUID()
    })
})

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
