function send(url, data) {
  return fetch(url + '.json', {
    method: 'POST',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data),
  })
}

export default send
