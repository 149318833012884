import { fetchUUID } from './user_uuid'
import { fetchLocation } from './location_utils'
import { WELCOME_PAGE_PATH } from './constants'
import send from './send'


$('input[name="quiz-type"]').click(function() {
  $('#quizTypeGateForm').submit()
})

$('#quizTypeGateForm').on('submit', function (e) {
  e.preventDefault()
  const form = $(this)
  const url = form.attr('action')
  const quizType = form.find('input[name="quiz-type"]:checked').val()

  if (!quizType) {
    alert('Please, select variant')
    return
  }

  const data = {
    quiz_type_gate: {
      location_id: fetchLocation(),
      uuid: fetchUUID(),
      quiz_type: quizType
    }
  }

  send(url, data)
    .finally(() => {
      console.log('sent')
      location.href = WELCOME_PAGE_PATH
    })
})
