import { fetchUUID } from './user_uuid'
import send from './send'

$('#button-dislike').click(function() {
  submit('0')
})

$('#button-like').click(function() {
  submit('1')
})

const timeout = 5000 // 10 sec
$('.last-question-submit-button').click(function() {
  const nextQuestionUrl = $('.question-answer-form').data('next-question-url')

  setTimeout(function() {
    submit('1')
    location.href = nextQuestionUrl
  }, timeout)
})

function submit(answer) {
  const form = $('.question-answer-form')
  const url = form.data('submit-url')
  const nextQuestionUrl = form.data('next-question-url')
  const question = form.find('.question').val()

  const data = {
    answer: {
      uuid: fetchUUID(),
      question: question,
      answer: answer,
    },
  }

  send(url, data)
    .finally(() => {
        location.href = nextQuestionUrl + '?' + 'uuid=' + fetchUUID()
    })
}
