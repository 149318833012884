import { fetchLocation } from './location_utils'

$(document).ready(function () {
  checkSetup()
})

function checkSetup() {
  if (fetchLocation())
    return

  $('.content-section').html('<div style="text-align: center; margin: 30px 0">' +
    'Please select your current location in ' +
    '<a style="color: #007bff; text-decoration: underline" href="/admin">admin panel</a>' +
  '</div>')
}
