var buttonWidth = 130

window.swipeStart = function(button, event) {
  button.startX = event.targetTouches[0].pageX
  button.style.zIndex = 1
}

window.swipeMove = function swipeMove(button, event) {
  var x = event.targetTouches[0].pageX
  var width = Math.abs(x - button.startX)
  button.style.width = buttonWidth + width + 'px'
  if (x < button.startX)
    button.style.left = -width + 'px'
}

window.swipeFinish = function swipeFinish(button, event) {
  delete button.style.width
  delete button.style.left
  button.click()
}
