import { fetchUUID } from './user_uuid'
import send from './send'
import { fetchLocation } from './location_utils'
import { EMAIL_GATE_PATH } from './constants'

const legalAge = 21;

function isOldEnough(day, month, year) {
  return new Date(year + legalAge, month - 1, day) <= new Date()
}


$('#ageGateForm').on('submit', function (e) {
  e.preventDefault()
  const form = $(this)
  const url = form.attr('action')

  const year = parseInt($('#age_gate_birthdate_1i').val())
  const month = parseInt($('#age_gate_birthdate_2i').val())
  const day = parseInt($('#age_gate_birthdate_3i').val())

  let error = false

  const errorAge = form.find('.error-age')
  if (!year || !month || !day) {
    errorAge.text('Please, select a date')
    error = true
  } else if (!isOldEnough(day, month, year)) {
    errorAge.text(`You must be ${legalAge} years of age or older to enter`)
    error = true
  } else {
    errorAge.text('')
  }

  const errorAgree = form.find('.error-agree')
  if (!this.elements.tos.checked) {
    errorAgree.text('You must agree with Privacy Policy to proceed')
    error = true
  } else {
    errorAgree.text('')
  }

  if (error)
    return

  const data = {
    age_gate: {
      location_id: fetchLocation(),
      uuid: fetchUUID(),
      birthdate: `${year}-${month}-${day}`,
    }
  }

  send(url, data)
    .finally(() => {
      location.href = EMAIL_GATE_PATH + '?' + 'uuid=' + fetchUUID()
    })
})

